import * as React from "react";
import {useEffect, useState} from "react";
import {Helmet} from 'react-helmet';


import '../styles.scss';
import {graphql, useStaticQuery} from "gatsby";

import parse from 'html-react-parser';
import FadeIn from "react-fade-in";

const IndexRoute = () => {

  const pageQuery = useStaticQuery(graphql`
      query MyQuery {
          markdownRemark {
              id
              html
              frontmatter {
                  title
              }
          }
      }
  `);


  const title = pageQuery.markdownRemark.frontmatter.title;
  const html = pageQuery.markdownRemark.html;


  const [currentInvitee, setCurrentInvitee] = useState('');
  const [isInvited, setIsInvited] = useState(false);


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (!token) return;


    fetch(`/.netlify/functions/invitation?token=${token}`)
      .then(r => r.json())
      .then(r => {
        if (r.error) return;
        setCurrentInvitee(r.invitee);
        setTimeout(() => {
          setIsInvited(true);
        }, 500);
      }, e => {
        console.error(e);
      })
      .catch(e => {
        console.error(e);
        setCurrentInvitee('');
      });

  }, []);


  return (
    <>
      <Helmet>
        <title>
          Jenni & Dávid esküvő
        </title>
      </Helmet>


      <p className={`ring ${isInvited ? null : 'loading'}`}>
        💍
      </p>

      {isInvited && <main>
        {currentInvitee && <FadeIn delay={100} transitionDuration={300}>
          <h1>
            Kedves {currentInvitee.toString()}!
          </h1>
          {parse(html)}
        </FadeIn>}

      </main>}
    </>
  );
};
export default IndexRoute;
